<template>
  <a-menu
    id="dddddd"
    class="user-menu"
    style=""
    v-model:openKeys="openKeys"
    v-model:selectedKeys="selectedKeys"
    mode="inline"
    @click="handleClick"
  >
    <a-menu-item key="basic">基本设置</a-menu-item>
    <a-menu-item key="certify">认证设置</a-menu-item>
    <a-menu-item key="binding">安全设置</a-menu-item>
    <!-- <a-menu-item key="notification">新消息通知</a-menu-item> -->
  </a-menu>
  <div class="edit">
    <div class="account-settings-title">
      {{$route.meta.name}}
    </div>
    <router-view />
  </div>
</template>
<script>
import { defineComponent, getCurrentInstance, ref } from 'vue'
import { MailOutlined, QqOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: { MailOutlined, QqOutlined, AppstoreOutlined, SettingOutlined },
  setup() {
    const { proxy } = getCurrentInstance()
    const selectedKeys = ref(['1'])
    const openKeys = ref(['sub1'])

    const handleClick = item => {
      proxy.$root.$router.push({ name: item.key })
    }

    const titleClick = e => {
      console.log('titleClick', e)
    }

    return {
      selectedKeys,
      openKeys,
      handleClick,
      titleClick
    }
  }
})
</script>
<style scoped>
.user-menu{
  position: absolute;
  left: 0;
  top: 24px;
  height: calc(100% - 48px);
  width: 256px;
}
.edit{
  padding-left: 256px;
}
.account-settings-title{
  font-size: 20px;
  padding-bottom: 20px;
}
</style>
